.homePageWrapper {
    background: #F5F0EC;
    min-height: 100vh;
    padding: 67px;
    padding-top: 170px;
}

.card-wrapper {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 16px;
    padding: 30px 0;
}

.disable {
    cursor: not-allowed;
}

.selectedSubPara {
    width: 100%;
    margin-bottom: 24px;
}

.selected-companies-btn {
    margin-right: 12px;
}

.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 35, 0.90);
    background-blend-mode: multiply;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 99;
}

.modal-wrapper {
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    width: 100%;
    max-width: 500px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.modal-open {
    overflow: hidden;
}

.modalHeading {
    margin-bottom: 16px;
}

.modalPara {
    margin-bottom: 14px;
}

.modalBtn {
    background: transparent;
    border: 2px solid #ffffff;
}

.modal-close {
    background: transparent;
    position: absolute;
    top: 25px;
    right: 35px;
    border: none;
    outline: none;
    cursor: pointer;
}

.popup-wrapper p {
    margin-bottom: 12px;
}

@media screen and (max-width: 1080px) {
    .card-wrapper {
        grid-template-columns: repeat(3, 1fr);
    }
}

@media screen and (max-width: 768px) {
    .card-wrapper {
        grid-template-columns: repeat(2, 1fr);
    }

    .homePageWrapper {
        padding: 132px 24px 24px;
    }
}

@media screen and (max-width: 528px) {
    .card-wrapper {
        grid-template-columns: 1fr;
    }
}