.text__heading_lg {
    font-size: 3.8vw;
    font-family: 'Mars Condensed';
    font-weight: 500;
    line-height: 3.2vw;
    color: #000023;
}

.text__heading_sm {
    font-size: 20px;
}

.text__body {
    font-size: 18px;
    line-height: 1.2;
    font-family: 'Object Sans';
}

.text__label_lg {
    font-size: 12px;
    text-transform: uppercase;
    font-family: 'Object Sans';
    color: #000023;
    line-height: 1.3;
    margin-bottom: 9px;
}

.text__white {
    color: #fff;
}

.text__cool_white {
    color: antiquewhite;
}

.text__black {
    color: #000000;
}

.text__cool_black {
    color: #000023;
}

.text__primary {
    color: #3283ff;
}

.text__danger {
    color: tomato;
}

.text__object {
    font-family: 'Object Sans';
}

.text__mars {
    font-family: 'Mars Condensed';
}

.text__center {
    text-align: center;
}

.text__right {
    text-align: right;
}

.text__left {
    text-align: left;
}

.text__700 {
    font-weight: 700;
}


@media screen and (max-width: 1080px) {
    .text__heading_sm {
        font-size: 18px;
    }
}

@media screen and (max-width: 768px) {
    .text__heading_lg {
        font-size: 9.3333vw;
        line-height: 8vw;
    }

    .text__heading_sm {
        font-size: 16px;
    }

    .text__body {
        font-size: 12px;
    }
}

@media screen and (max-width: 520px) {
    .text__body {
        font-size: 14px;
    }

    .text__heading_sm {
        font-size: 18px;
    }
}
