.footer {
  padding: 14px 68px;
  background: #000023;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.footer-title {
  text-align: center;
}

.copyright {
  color: #052E75;
  text-align: center;
  font-weight: 400;
  line-height: 26px;
}

@media screen and (max-width: 768px) {
  .footer {
    flex-direction: column;
    padding: 14px;
  }
}
