.btn {
    display: flex;
    justify-content: center;
    align-items: center;
    outline: none;
    cursor: pointer;
}

.btn__solid {
   border: none;
}

.btn__outline {
    border: 1px solid;
}

.customBtn {
    width: 101px;
    height: 73px;
    font-size: 30px;
    font-family: 'Mars Condensed';
    cursor: pointer;
}
