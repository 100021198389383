.formContainer {
    margin: 0 auto;
    display: flex;
    color: #ffffff;
}

.formHeading {
    font-size: 5.5vw;
    width: 60%;
    line-height: 1;
    font-family: 'Mars Condensed';
    font-weight: 400;
    text-transform: uppercase;
}

.para {
    padding-bottom: 8px;
}

.textWrapper,
.outerFormWrapper {
    width: 50%;
    padding: 135px 50px 60px;
    min-height: calc(100vh - 54px);
}

.outerWrapper {
    width: 60%;
    margin: 0 auto;
}

.textWrapper {
    background: url('../../assets/bg100.png');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}

.outerFormWrapper {
    background-color: #01226d;
    padding: 40px;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.loginDescription {
    width: 100%;
    max-width: 663px;
    margin-bottom: 20px;
}

.btn {
    width: 100%;
    height: 46.25px;
    font-size: 20px;
    margin-bottom: 8px;
    outline: none;
    border: none;
}

.btn {
    background: #3283ff;
    font-size: 30px;
    line-height: 36.25px;
    color: #ffffff;
    font-family: 'Mars Condensed';
    cursor: pointer;
}

.disabled {
    cursor: not-allowed;
}

.subHeading {
    padding: 16px 0;
}

@media screen and (max-width: 1024px) {
    .formContainer {
        width: 100%;
    }

    .textWrapper {
        width: 50%;
    }

    .outerWrapper {
        width: 80%;
    }

    .formHeading {
        font-size: 56px;
        line-height: 1.2;
    }

    .para {
        font-size: 18px;
    }
}

@media screen and (max-width: 768px) {
    .formContainer {
        flex-direction: column;
    }

    .textWrapper,
    .outerFormWrapper {
        width: 100%;
        padding: 25px 50px;
    }

    .outerFormWrapper {
        display: block;
        min-height: auto;
    }

    .textWrapper {
        padding-top: 140px;
        min-height: auto;
        padding-bottom: 20vh;
    }


    .outerWrapper {
        width: 95%;
        margin: 0 auto;
    }

    .formHeading {
        font-size: 48px;
        width: 100%;
    }

    .formSubheading,
    .para {
        font-size: 16px;
        text-align: center;
    }

    .formSubheading {
        font-weight: 900;
    }
}

@media screen and (max-width: 540px) {
    .formContainer {
        width: 100%;
    }

    .outerFormWrapper {
        padding: 24px;
    }
}

.input {
    width: 100%;
    padding: 14px 0;
    height: 38.25px;
    font-size: 18px;
    margin-bottom: 24px;
    outline: none;
    border: none;
    font-family: 'Object Sans';
    background: transparent;
    border-bottom: 1.5px solid #3283ff;
    color: #ffffff;
}

.input::placeholder {
    color: white;
    opacity: 0.8;
    font-weight: 100;
    font-size: 16;
}

input:-webkit-autofill {
    -webkit-box-shadow: 0 0 0 1000px transparent inset !important;
    -webkit-text-fill-color: #ffffff !important;
    transition: background-color 5000s ease-in-out 0s;
}

input:-moz-autofill {
    box-shadow: 0 0 0 1000px transparent inset !important;
    -moz-text-fill-color: #ffffff !important;
}

.input::selection {
    background: transparent;
    color: #ffffff;
}
