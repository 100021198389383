.header {
  position: absolute;
  top: 24px;
  left: 24px;
}


@media screen and (max-width: 768px) {
    .header {
      top: 14px;
    }
}

@media screen and (max-width: 528px) {
  .header svg {
    width: 180px;
  }
}
