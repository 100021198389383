.card {
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    background-color: rgba(255, 255, 255, 1);
    position: relative;
}

.selectedCard {
    background: url(../../assets/Rectangle.png), lightgray 0px 0px / 100% 100% no-repeat;
}

input[id="checkbox"]+label {
    display: none;
}

.checkbox {
    position: absolute;
    top: 15px;
    right: 15px;
}

.company {
    font-size: 32px;
    color: #ffffff;
    line-height: 1.1;
}

.website {
    margin-right: 4px;
}

.card-details {
    margin-bottom: 16px;
    background-image: url('../../assets/cardBG.png');
    background-size: cover;
    height: 196px;
    padding: 24px;
    color: #ffffff;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.company-link {
    font-size: 12px;
    font-weight: 400;
    line-height: 15.6px;
    color: #ffffff;
    padding-bottom: 3px;
    line-height: 31px;
    border-bottom: 1px solid #ffffff;
}

.stage-investment-wrapper {
    display: flex;
    padding: 24px;
}

.stage,
.investment {
    width: 50%;
}

.stage {
    padding-right: 16px;
}

.investment-company {
    font-weight: bold;
    /* border-bottom: 1px solid #e1e1e1; */
    padding: 5px 0;
    font-family: 'Object Sans';
}

input:checked::before {
    content: '\2713';
    font-size: 14px;
    color: #fff;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-family: 'Arial', sans-serif;
}

@media screen and (max-width: 768px) {
    .company {
        font-size: 24px;
    }

    .investment-company {
        font-size: 12px;
    }
}

.recommended {
    position: absolute;
    top: -10px;
    left: 20px;
    width: 120px;
}

.recommended-btn {
    padding: 6px 12px;
    border: none;
    border-radius: 40px;
    background: red;
    color: #ffffff;
    text-transform: uppercase;
    -webkit-box-shadow: 2px 2px 5px 0px rgba(0, 0, 255, 1);
    -moz-box-shadow: 3px 6px 5px 0px rgba(0, 0, 255, 1);
    box-shadow: 2px 2px 2px 0px rgba(0, 0, 255, 1);
}
